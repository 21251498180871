<template>
    <div class="page">
        <HeadContent>
            <div slot="bgImg" style="">
                <img src="../../assets/common/topTitleBg.png" style="margin-top: 70px;">
            </div>
            <div slot="title" style="width: 100%">资讯中心</div>
        </HeadContent>

        <div style="width: 100%;background-color: #f5f5f5">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item,index) in activeinfo" :key="index" :label="item.label" :name="item.name"/>
            </el-tabs>
        </div>

        <div class="news-bbb-box d_jump">
            <div class="container">
                <div class="news">
                    <div class="mtitle">企业新闻</div>
                    <div class="news-cbox">
                        <div class="new-li-hm qy" v-for="(item,index) in qyxwList" :key="index"
                             @click="goMore(item.id,'企业新闻')">
                            <div class="new-col">
                                <div>
                                    <span class="qyicon"></span>
                                    <span></span>
                                </div>
                                <div class="news-t">{{ item.title }}</div>
                            </div>
                            <div class="news-date">{{ formattedCreateDate(item.createDate) }}</div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <div class="news-more-btn" @click="goRouter('企业新闻')">加载更多</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="news-bbb-box flfg d_jump">
            <div class="container">
                <div class="news">
                    <div class="mtitle">法律法规</div>
                    <div class="news-cbox">
                        <div class="new-li-hm fl" v-for="(item,index) in flfgList" :key="index"
                             @click="goMore(item.id,'法律法规')">
                            <div class="new-col">
                                <div>
                                    <span class="flicon"></span>
                                    <span></span>
                                </div>
                                <div class="news-t">{{ item.title }}</div>
                            </div>
                            <div class="news-date">{{ formattedCreateDate(item.createDate) }}</div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <div class="news-more-btn" @click="goRouter('法律法规')">加载更多</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="news-bbb-box d_jump">
            <div class="container">
                <div class="news">
                    <div class="mtitle">行业动态</div>
                    <div class="news-cbox">
                        <div class="new-li-hm hy" v-for="(item,index) in hydtList" :key="index"
                             @click="goMore(item.id,'行业动态')">
                            <div class="new-col">
                                <div>
                                    <span class="hyicon"></span>
                                    <span></span>
                                </div>
                                <div class="news-t">{{ item.title }}</div>
                            </div>
                            <div class="news-date">{{ formattedCreateDate(item.createDate) }}</div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <div class="news-more-btn" @click="goRouter('行业动态')">加载更多</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import newsApi from "@/api/news.js"

    export default {
        data() {
            return {
                activeName: 'one',
                activeinfo: [
                    {label: '企业新闻', name: 'one'},
                    {label: '法律法规', name: 'two'},
                    {label: '行业动态', name: 'five'}
                ],
                qyxwList: [],
                flfgList: [],
                hydtList: []
            }
        },
        watch: {
            $route: {
                handler(newName, oldName) {
                    //执行代码
                    let index = newName.query.index
                    if (typeof this.activeinfo[index - 1] != "undefined") {
                        this.activeName = this.activeinfo[index - 1].name
                        this.handleClick({
                            index: index - 1
                        })
                    }

                },
                deep: true
            }
        },
        mounted() {
            newsApi.listNews(1).then((response) => {
                this.qyxwList = response.rows
            })

            newsApi.listNews(2).then((response) => {
                this.flfgList = response.rows
            })

            newsApi.listNews(3).then((response) => {
                this.hydtList = response.rows
            })

            if (typeof this.activeinfo[this.$route.query.index] != "undefined") {
                this.activeName = this.activeinfo[this.$route.query.index].name
            }
        },
        methods: {
            handleClick(tab, event) {
                const jump = document.querySelectorAll('.d_jump')
                const total = jump[tab.index].offsetTop
                let distance = (document.documentElement.scrollTop || document.body.scrollTop)
                // 平滑滚动，时长500ms，每10ms一跳，共50跳
                let step = total / 50
                if (total > distance) {
                    smoothDown()
                } else {
                    const newTotal = distance - total
                    step = newTotal / 50
                    smoothUp()
                }

                function smoothDown() {
                    if (distance < total) {
                        distance += step
                        document.body.scrollTop = distance
                        document.documentElement.scrollTop = distance
                        setTimeout(smoothDown, 10)
                    } else {
                        document.body.scrollTop = total
                        document.documentElement.scrollTop = total
                    }
                }

                function smoothUp() {
                    if (distance > total) {
                        distance -= step
                        document.body.scrollTop = distance
                        document.documentElement.scrollTop = distance
                        setTimeout(smoothUp, 10)
                    } else {
                        document.body.scrollTop = total
                        document.documentElement.scrollTop = total
                    }
                }
            },
            goMore(id, title) {
                this.$router.push({
                    name: "article",
                    query: {id, title}
                })
            },
            goRouter(title) {
                this.$router.push({
                    name: "articles",
                    query: {title}
                })
            },
            formattedCreateDate(time) {
                const date = new Date(time)
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            }

        }
    }
</script>

<style scoped lang="scss">
    $TabHeadHeight: 60px;
    ::v-deep .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        max-width: 1200px;
        height: $TabHeadHeight;
        background-color: #f5f5f5;
        margin: 0 auto;

        .el-tabs__nav {
            width: 100%;
        }

    }

    ::v-deep .el-tabs__nav-wrap::after {
        display: none;
    }

    ::v-deep .el-tabs__header {
        margin-bottom: 0;
    }

    ::v-deep .el-tabs__item {
        height: $TabHeadHeight;
        line-height: 60px;
    }

    .news {
        .news-cbox {
            padding-top: 40px;
            .new-li-hm {
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                background: #FBFBFB;
                margin-bottom: 12px;

                &:hover {
                    cursor: pointer;
                    color: #1320E8;
                    background: #F5F5F5;
                }

            }

            .new-col {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                div:nth-child(1) {
                    display: flex;
                    span:nth-child(1) {
                        width: 26px;
                        height: 26px;
                        background-image: url("./images/new.png");
                    }

                    span:nth-child(2) {
                        margin: 0 18px;
                        width: 1px;
                        height: 23px;
                        background-color: #DBDBDB;
                    }
                }

                .news-t {
                    max-width: 700px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .btn-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 10px;
            &:hover {
                cursor: pointer;
            }
        }

        .news-more-btn {
            position: relative;
            width: 84px;
            height: 30px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #888888;
            opacity: 0.76;

            &:after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #888;
            }
        }

        .news-more-btn:hover {
        }
    }

    .news-bbb-box {
        width: 100%;
        padding: 60px 0;
    }

    .flfg {
        background-color: #fbfbfb;
    }

    .container {
        margin-bottom: 0;
    }

    .qy {
        .qyicon {
            background-image: url("./images/new.png") !important;
        }
        &:hover {
            .qyicon {
                background-image: url("./images/new1.png") !important;
            }
        }
    }

    .fl {
        .flicon {
            background-image: url("./images/chen.png") !important;
        }
        &:hover {
            .flicon {
                background-image: url("./images/chen1.png") !important;
            }
        }
    }

    .hy {
        .hyicon {
            background-image: url("./images/vo.png") !important;
        }
        &:hover {
            .hyicon {
                background-image: url("./images/vo1.png") !important;
            }
        }
    }

</style>
